import styled from '@emotion/styled'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup, { RadioGroupProps } from '@mui/material/RadioGroup'
import Image, { StaticImageData } from 'next/image'
import {
  Control,
  Controller,
  FieldError,
  RegisterOptions
} from 'react-hook-form'
import { BorderRadius, Colors, FontWeight } from 'src/constants/theme'

const StyledChip = styled(RadioGroup)<{
  columns: number | undefined
  horizontal: boolean | undefined
  color: string | undefined
  error?: boolean
  filled?: boolean
  columnBp?: string
}>`
  && {
    ${({ horizontal }) => horizontal && `display: flex;`}
    ${({ horizontal }) => horizontal && `flex-direction: row;`}
    ${({ horizontal }) => horizontal && `justify-content: space-between;`}

    ${({ theme, columnBp }) => theme.breakpoints.up(columnBp ?? 'md')} {
      ${({ columns }) => columns && `display: grid;`}
      ${({ columns }) =>
        columns && `grid-template-columns: repeat(${columns}, 1fr);`}
      ${({ columns }) => columns && `gap: 10px;`}
      ${({ columns }) => columns && `margin-top: 0px;`}
    }

    .radio-container {
      border: 1px solid;
      border-color: ${Colors.grey300};
      background-color: ${Colors.grey300};
      border-radius: ${BorderRadius.large}px;
      height: 50px;
      align-items: center;
      .in {
        padding-left: 10px !important;
        height: 50px;
      }
      ${({ error }) => error && `border: 1px solid ${Colors.red};`}

      margin: 5px 0px;
      img {
        width: 5% !important;
      }
      label {
        padding-left: 11px;
        width: 95%;
        justify-content: space-between;
        span {
          color: ${Colors.black400};
        }
      }
    }
    .radio-container .si {
      ${({ color }) => (color ? `color:${color}` : `color: ${Colors.grey500}`)};
    }
    .radio-container:has(.Mui-checked.MuiRadio-root) {
      border-color: ${Colors.blue500};
      background-color: ${Colors.white};
      color: ${Colors.blue500};
    }
    .radio-container:has(.Mui-checked.MuiRadio-root) .si {
      color: ${Colors.blue500};
    }
    padding-top: ${({ horizontal }) => (horizontal ? '0px' : '25px')};
    padding-bottom: 30px;
    gap: ${({ horizontal }) => (horizontal ? '5px 5px' : '5px 0px')};
  }
`

const StyledExpandedChip = styled(RadioGroup)<{
  columns: number | undefined
  horizontal: boolean | undefined
  color: string | undefined
  error?: boolean
  filled?: boolean
  columnBp?: string
}>`
  && {
    ${({ horizontal }) => horizontal && `display: flex;`}
    ${({ horizontal }) => horizontal && `flex-direction: row;`}
    ${({ horizontal }) => horizontal && `justify-content: space-between;`}

    ${({ theme, columnBp }) => theme.breakpoints.up(columnBp ?? 'md')} {
      ${({ columns }) => columns && `display: grid;`}
      ${({ columns }) =>
        columns && `grid-template-columns: repeat(${columns}, 1fr);`}
      ${({ columns }) => columns && `gap: 20px;`}
      ${({ columns }) => columns && `margin-top: 0px;`}
    }

    .radio-container {
      border: 1px solid;
      border-color: ${Colors.grey400};
      background-color: ${Colors.white};
      border-radius: ${BorderRadius.xxLarge}px;
      align-items: center;
      padding-left: 10px !important;
      ${({ error }) => error && `border: 1px solid ${Colors.red};`}
      .description {
        color: ${Colors.black400};
        font-size: 14px;
        font-style: normal;
        font-weight: ${FontWeight.regular};
        line-height: 20px;
      }
      margin: 5px 0px;
      img {
        width: 5% !important;
      }
      label {
        padding: 10px 11px;
        width: 95%;
        justify-content: space-between;
      }
      span {
        color: ${Colors.black400};
        font-weight: ${FontWeight.semiBold};
        line-height: 20px;
      }
    }
    .radio-container .si {
      color: ${Colors.grey500};
    }
    .radio-container:has(.Mui-checked.MuiRadio-root) {
      border-color: ${Colors.blue500};
      ${({ filled }) =>
        `background-color: ${filled ? Colors.blue500 : Colors.white}`};
      ${({ filled }) => `color: ${filled ? Colors.white : Colors.blue500}`};

      .si {
        ${({ filled }) => `color: ${filled ? Colors.white : Colors.blue500}`};
      }
      .description {
        ${({ filled }) => `color: ${filled ? Colors.white : Colors.black400}`};
      }
      span {
        ${({ filled }) => `color: ${filled ? Colors.white : Colors.black400}`};
      }
    }
    padding-top: ${({ horizontal }) => (horizontal ? '0px' : '25px')};
    padding-bottom: 30px;
    gap: ${({ horizontal }) => (horizontal ? '15px' : '0px')};
  }
`
type IEntry = {
  label: string
  description?: string
  icon?: string
  src?: string
}

type IPropsEntry = {
  entry: IEntry | string
  expanded: boolean
  filled: boolean
  removedIcons: boolean
  onSelectValue?: (value: string) => void
  type: string
  value: string
  controlled: boolean
}

const Entry = ({
  entry,
  expanded,
  filled,
  removedIcons,
  onSelectValue,
  type,
  value,
  controlled
}: IPropsEntry) => {
  const { label, description, icon, src } =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    typeof entry === 'string' ? ({ label: entry } as any) : entry
  return (
    <div
      key={type}
      className="radio-container mb-2  min-w-fit rounded-xl border px-2 hover:cursor-pointer"
      onClick={onSelectValue ? () => onSelectValue(type) : undefined}
    >
      <div
        className={`flex flex-row px-1 ${
          expanded ? 'h-auto gap-1 md:h-[70px]' : 'h-full'
        } items-center`}
      >
        {!src ? (
          <i
            className={`si ${icon ?? 'si-warehouse'} ${expanded && 'text-2xl'}`}
          />
        ) : undefined}
        {src && (
          <Image
            src={src}
            alt="radio icon"
            width={24}
            height={24}
            sizes="(max-width: 768px) 15vw,
                  10vw"
          />
        )}

        <FormControlLabel
          key={type}
          value={type}
          checked={controlled ? value === type : undefined}
          sx={{ marginLeft: 0 }}
          control={
            <Radio
              icon={
                removedIcons ? (
                  <div />
                ) : (
                  <i className="si si-circle-check text-grey-500"></i>
                )
              }
              checkedIcon={
                removedIcons ? (
                  <div />
                ) : (
                  <i
                    className={`si si-circle-xmark ${
                      filled ? 'text-white-500' : 'text-blue-500'
                    }`}
                  ></i>
                )
              }
            />
          }
          label={
            <div className="flex flex-col justify-center">
              <div>{label}</div>
              {description && <div className="description">{description}</div>}
            </div>
          }
          labelPlacement="start"
        />
      </div>
    </div>
  )
}

type IProps = RadioGroupProps & {
  id?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control?: Control<any, any>
  error?: FieldError | boolean | undefined
  rules?: RegisterOptions
  color?: string
  entries: {
    [key: string]:
      | {
          label: string
          description?: string
          icon?: string
          src?: string
          deprecated?: boolean
        }
      | string
  }
  icon?: StaticImageData
  columns?: number
  expanded?: boolean
  filled?: boolean
  removedIcons?: boolean
  horizontal?: boolean //Horizontal layout
  onSelectValue?: (value: string) => void
  customChips?: React.ReactNode
  columnBp?: string
}

export default function BaseChip({
  control,
  id,
  rules,
  entries,
  columns,
  expanded,
  horizontal,
  color,
  filled,
  removedIcons,
  onSelectValue,
  customChips,
  ...props
}: IProps) {
  const Chip = expanded ? StyledExpandedChip : StyledChip

  const chips = customChips
    ? customChips
    : Object.entries(entries)
        .filter(([, item]) => typeof item !== 'string' && !item.deprecated)
        .map(([type, entry]) => (
          <Entry
            key={type}
            entry={entry}
            expanded={!!expanded}
            filled={!!filled}
            removedIcons={!!removedIcons}
            onSelectValue={onSelectValue}
            type={type}
            value={props.value}
            controlled={!control}
          />
        ))

  return control && id ? (
    <Controller
      name={id}
      control={control}
      rules={rules}
      render={({ field }) => (
        <Chip
          {...props}
          {...field}
          columns={columns}
          filled={filled}
          horizontal={horizontal}
          color={color}
          error={!!props.error}
          columnBp={props.columnBp}
        >
          {chips}
        </Chip>
      )}
    />
  ) : (
    <Chip
      {...props}
      columns={columns}
      filled={filled}
      horizontal={horizontal}
      color={color}
      error={!!props.error}
      value={props.value}
      columnBp={props.columnBp}
    >
      {chips}
    </Chip>
  )
}
