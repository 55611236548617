import styled from '@emotion/styled'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import { Colors } from '../../../constants/theme'
import FlexBox from '../../FlexBox'

export const StyledInnerContainer = styled(FlexBox)`
  && {
    position: absolute;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: ${Colors.white};
    padding: 18px 12px 58px;

    & > .modal-close-icon {
      cursor: pointer;
    }

    ${({ theme }) => theme.breakpoints.up('md')} {
      width: 628px;
      height: 718px;
      border-radius: 16px;
      justify-content: initial;
      padding: 30px 22px 60px;
    }
  }
`

export const StyledDividerDimentionsLeft = styled(Divider)`
  && {
    position: absolute;
    translate: -20px 95px 200px;
    transform: rotate(30deg);
    margin-top: 50px;
    width: 140px;
    ${({ theme }) => theme.breakpoints.up('md')} {
      translate: -28px 120px 200px;
      margin-top: 50px;
      width: 180px;
    }
  }
`
export const StyledDividerDimentionsRight = styled(Divider)`
  && {
    position: absolute;
    translate: 127px -36px 54px;
    transform: rotate(-30deg);
    width: 140px;
    ${({ theme }) => theme.breakpoints.up('md')} {
      translate: 147px -48px 54px;
      width: 180px;
    }
  }
`

export const StyledModalTitle = styled(Typography)`
  && {
    color: ${Colors.text};
    font-weight: 700;
    line-height: 47px;
    font-size: 20px;
    margin: 0 auto;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    && {
      font-size: 28px;
    }
  }
`
export const StyledSquareSubTitle = styled(Typography)`
  && {
    color: ${Colors.text};
    font-weight: 700;
    font-size: 20px;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  ${({ theme }) => theme.breakpoints.up('md')} {
    && {
      font-size: 22px;
      justify-self: left;
      text-align: start;
      justify-content: left;
      width: 80%;
    }
  }
`

export const StyledSizeImageContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`

export const StyledModalOptions = styled(FlexBox)`
  width: auto;
  border-radius: 40px;
  background-color: ${Colors.grey300};
`

export const StyledModalDescription = styled(Typography)`
  && {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    color: ${Colors.black300};
    padding: 23px 0 23px;
    max-width: 650px;
  }
`

export const StyledDots = styled.div`
  display: flex;
  gap: 2px;
  margin-top: 20px;
`
