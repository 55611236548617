/* eslint-disable @typescript-eslint/no-explicit-any */
import useMediaQuery, {
  UseMediaQueryOptions
} from '@mui/material/useMediaQuery'

function useBreakpoints(opt?: UseMediaQueryOptions) {
  const isDownSm = useMediaQuery((tm: any) => tm.breakpoints.down('sm'), opt)
  const isDownMd = useMediaQuery((tm: any) => tm.breakpoints.down('md'), opt)
  const isDownLg = useMediaQuery((tm: any) => tm.breakpoints.down('lg'), opt)

  // Better use these ones:
  const isUpSm = useMediaQuery((tm: any) => tm.breakpoints.up('sm'), opt)
  const isUpMd = useMediaQuery((tm: any) => tm.breakpoints.up('md'), opt) // This is the standard for desktop
  const isUpLg = useMediaQuery((tm: any) => tm.breakpoints.up('lg'), opt)

  return {
    isDownSm,
    isDownMd,
    isDownLg,
    isUpSm,
    isUpMd,
    isUpLg
  }
}

export default useBreakpoints
