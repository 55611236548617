import Close from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Modal from '@mui/material/Modal'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import { SxProps } from '@mui/material/styles'
import React from 'react'
import { iOS } from 'src/constants/device'
import { Colors } from '../constants/theme'
import useBreakpoints from '../hooks/useBreakpoints'

type IProps = {
  title?: string
  subtitle?: string
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any
  sx?: SxProps
  bigTitle?: boolean
  hideMd?: boolean
  width?: string
  bgColor?: string
  customTitle?: React.ReactNode
}

function Body({
  title,
  subtitle,
  children,
  setIsOpen,
  bigTitle,
  bgColor,
  customTitle
}: IProps) {
  return (
    <>
      <div
        className={`left-0 top-0 mb-0 flex w-full justify-between ${bgColor ? bgColor : 'bg-white-500'} p-[10px]`}
      >
        <div>
          {title && !customTitle ? (
            <p
              className={`ml-[5px] px-[5px] pt-[5px] text-[18px] font-semibold leading-[40px] text-black-400  ${bigTitle ? 'md:px-[30px] md:pt-[20px] md:text-[32px]' : 'md:text-[20px]'}`}
            >
              {title}
            </p>
          ) : (
            <div>{customTitle}</div>
          )}

          {subtitle && (
            <p className="ml-0.5 px-0.5 pt-0.5 text-[15px] font-semibold leading-[20px] text-black-400">
              {subtitle}
            </p>
          )}
        </div>

        <div className="hidden md:block">
          <IconButton aria-label="Example" onClick={() => setIsOpen(false)}>
            <Close sx={{ color: Colors.grey500 }} />
          </IconButton>
        </div>
      </div>

      <div
        className={`overflow-y-auto ${bgColor ? bgColor : 'bg-white-500'} p-[10px] pt-0 md:overflow-hidden`}
      >
        {children}
      </div>
    </>
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const modalStyles: any = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '0.5px solid #eceff1',
  borderRadius: '20px'
}

function ModalDrawer({
  title,
  subtitle,
  isOpen,
  setIsOpen,
  children,
  sx,
  hideMd,
  bigTitle,
  width,
  bgColor,
  customTitle
}: IProps) {
  const { isUpMd, isDownMd } = useBreakpoints()
  if (isUpMd && hideMd) return null

  const container =
    typeof window !== undefined ? () => window.document.body : undefined

  if (!isOpen) return null

  return isDownMd ? (
    <SwipeableDrawer
      container={container}
      anchor="bottom"
      open={isOpen}
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
      sx={{ zIndex: (theme) => theme.zIndex.modal }}
      disableBackdropTransition={!iOS}
      disableDiscovery={!iOS}
      ModalProps={{ keepMounted: true }}
    >
      <div
        className={`visible absolute inset-x-0 top-[-15px] h-[16px] rounded-t-xl  ${bgColor ? bgColor : 'bg-white-500'}`}
      >
        <div className="absolute left-[calc(50%-40px)] top-[8px] h-[6px] w-[80px] rounded-full bg-grey-500" />
      </div>

      <div className="overflow-y-auto">
        <Body
          title={title}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          subtitle={subtitle}
          bigTitle={bigTitle}
          bgColor={bgColor}
          customTitle={customTitle}
        >
          {children}
        </Body>
      </div>
    </SwipeableDrawer>
  ) : (
    <Modal open={isOpen} onClose={() => setIsOpen(false)}>
      <Box
        sx={{
          ...modalStyles,
          width: width ? width : '40%',
          maxWidth: '720px',
          minWidth: '500px',
          overflow: 'hidden',
          ...sx
        }}
        role="presentation"
      >
        <Body
          title={title}
          subtitle={subtitle}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          bigTitle={bigTitle}
        >
          {children}
        </Body>
      </Box>
    </Modal>
  )
}

export default React.memo(ModalDrawer)
